<template>
  <div>
    <div class="my-4 text-center">
      <Headline :title="club.title" />
      <span
        class="text-sm uppercase text-g7-blue lg:text-xl"
        v-html="club.sub_title"
      />
    </div>
    <div v-for="(membership, index) in club.memberships" :key="index">
      <div
        class="flex w-full cursor-pointer border bg-table-orange"
        @click="toggle(index)"
      >
        <div class="flex w-full flex-col lg:flex-row lg:justify-between">
          <h2
            class="mx-2 mt-2 text-sm font-black uppercase sm:text-base lg:m-4 lg:text-xl"
            v-html="membership.title_left"
          />
          <h2
            class="mx-2 mb-2 text-sm uppercase sm:text-base lg:m-4 lg:text-xl"
            v-html="membership.title_right"
          />
        </div>
        <div class="flex w-16 items-center justify-center border-l">
          <div class="w-4 lg:w-8">
            <IconsUp
              class="fill-g7-blue transition"
              :class="{ 'rotate-180 transition': open == index }"
            />
          </div>
        </div>
      </div>
      <div
        v-if="membership.advantages?.length != 0"
        class="w-full border py-2 lg:py-0"
        :class="{ hidden: open != index }"
      >
        <ul>
          <li
            v-for="(advantage, subindex) in membership.advantages"
            :key="subindex"
            class="mx-2 flex lg:mx-5 lg:my-2"
          >
            <div
              class="mr-2 text-sm uppercase sm:text-base lg:text-xl"
              v-html="'-'"
            ></div>
            <div
              class="text-sm uppercase sm:text-base lg:text-xl"
              v-html="advantage"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { Ref } from "vue";
import type { CruiselineClub } from "~~/types/cruiselines";

defineProps<{
  club: CruiselineClub;
}>();
const open: Ref<number | null> = ref(null);

function toggle(index: number) {
  if (open.value === index) {
    open.value = null;
  } else {
    open.value = index;
  }
}
</script>
