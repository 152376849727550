import voca from "voca";

// someCamelExample
export const toCamel = (s: string) => voca.camelCase(s);

// some-kebab-example
export const toKebab = (s: string) => voca.kebabCase(s);

// some_snake_example
export const toSnake = (s: string) => voca.snakeCase(s);

// SOME_UPPER_SNAKE_EXAMPLE
export const toUpperSnake = (s: string) => voca.snakeCase(s).toUpperCase();
