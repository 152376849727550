<template>
  <div class="hidden w-full text-g7-blue lg:block">
    <table class="w-full">
      <thead>
        <tr>
          <td v-for="col in cols" :key="col" class="border-2 p-4 uppercase">
            <div
              v-if="col !== 'Alle Monate'"
              class="flex items-center justify-center"
              @click="orderBy(col)"
            >
              <h2 v-html="col" />
              <IconsUp
                :class="{
                  '!rotate-0': mapping[col] === filter && mode === 'ASC',
                }"
                class="size-6 rotate-180 cursor-pointer fill-g7-blue"
              />
            </div>
            <div
              v-if="col === 'Alle Monate'"
              class="flex items-center justify-center"
            >
              <FieldDropdown v-model="month" :options="months" />
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(cruise, index) in filteredCruises" :key="index">
          <td class="border-2 bg-table-orange p-4">
            <div class="flex justify-center">
              {{ convertDate(cruise.departure_date, "DD.MM.YYYY") }} -
              {{ convertDate(cruise.arrival_date, "DD.MM.YYYY") }}
            </div>
          </td>
          <td class="border-2 p-4">
            <div
              class="flex justify-center"
              v-html="cruise.lafs[0].amount.formatted"
            />
          </td>
          <td class="border-2 bg-table-orange p-4">
            <div
              class="flex justify-center"
              v-html="cruise.lafs[1].amount.formatted"
            />
          </td>
          <td class="border-2 p-4">
            <div
              class="flex justify-center"
              v-html="cruise.lafs[2].amount.formatted"
            />
          </td>
          <td class="border-2 bg-table-orange p-4">
            <div
              class="flex justify-center"
              v-html="cruise.lafs[3].amount.formatted"
            />
          </td>
          <td class="border-2 p-4">
            <div class="flex justify-end">
              <G7Button
                :label="cruise.action_button.label"
                :to="cruise.action_button.context"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import type { SimilarCruise } from "~~/types/cruise";

const props = defineProps<{
  cols: Array<string>;
  cruises: Array<SimilarCruise>;
}>();

const months = computed(() => {
  return props.cruises
    .map((item) => ({
      label: `${convertDate(item.departure_date, "MON YYYY")}`,
      value: `${convertDate(item.departure_date, "YYYY-MM")}`,
    }))
    .reduce(
      (options, item) => {
        if (options.filter((o) => o.value === item.value).length === 0) {
          options.push(item);
        }
        return options;
      },
      [
        {
          label: "Alle Monate",
          value: "Alle",
        },
      ]
    );
});
const month = ref(months.value[0]);
const filteredCruises = ref(props.cruises);

const mapping = {
  Innenkabine: 0,
  Außenkabine: 1,
  Balkonkabine: 2,
  Suite: 3,
  Termin: 4,
};

const filter = ref();
const mode = ref("ASC");
function orderBy(col: string) {
  if (mapping[col] === filter.value) {
    if (mode.value === "ASC") mode.value = "DESC";
    else if (mode.value === "DESC") mode.value = "ASC";
  } else {
    filter.value = mapping[col];
    mode.value = "ASC";
  }

  const newCruises = filteredCruises.value;

  // Orders by Cabin type
  if (filter.value < 4) {
    if (mode.value === "ASC") {
      newCruises.sort(function (a, b) {
        return (
          a.lafs[filter.value].amount.value - b.lafs[filter.value].amount.value
        );
      });
    }
    if (mode.value === "DESC") {
      newCruises.sort(function (a, b) {
        return (
          b.lafs[filter.value].amount.value - a.lafs[filter.value].amount.value
        );
      });
    }
  }

  // Orders by Termin
  if (filter.value === 4) {
    if (mode.value === "ASC") {
      newCruises.sort(function (a, b) {
        return (
          new Date(a.departure_date).getTime() -
          new Date(b.departure_date).getTime()
        );
      });
    }
    if (mode.value === "DESC") {
      newCruises.sort(function (a, b) {
        return (
          new Date(b.departure_date).getTime() -
          new Date(a.departure_date).getTime()
        );
      });
    }
  }

  filteredCruises.value = newCruises;
}

watch(month, () => {
  filter.value = undefined;
  mode.value = "ASC";

  month.value.value === "Alle"
    ? (filteredCruises.value = props.cruises)
    : (filteredCruises.value = props.cruises.filter((item) =>
        item.departure_date.includes(month.value.value)
      ));
});
</script>
