<template>
  <div>
    <HomeHeroSlider class="mt-2 sm:mt-4 lg:mt-auto" />
    <ShipHeader
      :title="response.name"
      :cruiseline="response.cruise_line"
      :category="response.category"
      :rating="response.rating"
      :logo="response.cruise_line_logo"
    />
    <hr class="hidden border-2 md:block" />

    <FieldParagraph
      class="m-4"
      :title="response.description.label"
      :text="response.description.value"
    />
    <hr class="hidden border-2 md:block" />
    <ShipSpecificationTable :ship="response.ship_details" class="m-4" />
    <Calendar :calendar="response.cruise_calendar" class="m-4" />
    <hr class="hidden border-2 md:block" />

    <div
      v-if="
        response.facilities_section &&
        response.facilities_section.facilities.length != 0
      "
    >
      <Headline
        class="m-4 text-center"
        :title="response.facilities_section.title"
      />
      <List
        class="mx-4 mb-8 hidden md:block"
        :items="response.facilities_section.facilities"
        :button="response.facilities_section.load_more_label"
      />

      <CardSlider
        v-slot="{ item, id }"
        class="mb-8 md:hidden"
        :items="response.facilities_section.facilities"
      >
        <Infocard :id="id" class="mx-8 h-min grow" :item="item" />
      </CardSlider>

      <hr class="hidden border-2 md:block" />
    </div>

    <ShipCabinList
      v-if="response.cabin_types_section.cabin_types.length > 0"
      class="mx-4 mb-8 hidden sm:block"
      :title="response.cabin_types_section.title"
      :dropdown="response.cabin_types_section.cabin_type_select_box"
      :items="response.cabin_types_section.cabin_types"
      :buttons="response.cabin_types_section.load_more_labels"
    />

    <div class="mx-4 mb-8 sm:hidden">
      <Headline
        v-if="
          response.cabin_types_mobile_section.title &&
          response.cabin_types_mobile_section.cabin_types.length > 0
        "
        class="text-center"
        :title="response.cabin_types_mobile_section.title"
      />
      <div
        v-for="(item, index) in response.cabin_types_mobile_section.cabin_types"
        :key="index"
      >
        <ShipCabinCard class="my-6" :item="item" />
      </div>
    </div>

    <hr
      v-if="response.cabin_types_section.cabin_types.length > 0"
      class="hidden border-2 md:block"
    />

    <ShipDecks
      v-if="response.deck_section.deck_details_list.length > 0"
      class="mx-4 mb-8"
      :title="response.deck_section.title"
      :dropdown="response.deck_section.deck_select_box"
      :items="response.deck_section.deck_details_list"
    />
    <hr
      v-if="response.deck_section.deck_details_list.length > 0"
      class="hidden border-2 md:block"
    />

    <ShipReviews align="center" class="m-4" :data="shipRatings" />

    <hr class="hidden border-2 md:block" />
    <CruiseList
      class="mx-4 mb-8"
      :title="cruises.title"
      :button="cruises.action_button"
      :offers="cruises.cruises"
    />
  </div>
</template>

<script setup lang="ts">
import type { Links } from "~~/types/layout";
import type {
  CruiseListResponse,
  ShipRatingsResponse,
  ShipResponse,
} from "~~/types/response";

const props = defineProps<{
  links: Links;
}>();
const { data: response } = await useGo7seasApi<ShipResponse>(
  props.links.self.href
);
useDiaShow().set(response.value?.dia_show.dias ?? []);

const { data: cruises } = await useGo7seasApi<CruiseListResponse>(
  props.links.cruises.href
);

const { data: shipRatings } = await useGo7seasApi<ShipRatingsResponse>(
  apiUrl(`/catalog/ships/${useRoute().params.slug[0]}/ratings`)
);

updateDefaultLayout(response.value);
</script>
