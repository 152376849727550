<template>
  <ModalBackground @click="onClickOutside">
    <ModalBody ref="recommendModal" @toggle="emit('toggleModal')">
      <Guideline
        class="my-4"
        :title="response?.title"
        :text="response?.guideline"
      />

      <Bar :title="response?.form.title" />

      <div class="text-g7-blue">
        <div class="m-4 flex flex-col justify-between lg:flex-row">
          <LabelInput
            v-model="recommendation.name"
            class="my-2"
            name="name"
            :item="response?.form.name_field"
          />
          <LabelInput
            v-model="recommendation.e_mail"
            class="my-2"
            name="email"
            :item="response?.form.e_mail_field"
          />
        </div>

        <div class="m-4 flex flex-col justify-between lg:flex-row">
          <LabelInput
            v-model="recommendation.friends_name"
            class="my-2"
            name="friend"
            :item="response?.form.friends_name_field"
          />
          <LabelInput
            v-model="recommendation.friends_e_mail"
            class="my-2"
            name="friends_email"
            :item="response?.form.friends_e_mail_field"
          />
        </div>
        <LabelTextarea
          v-model="recommendation.message"
          class="m-4"
          name="message_field"
          :item="response?.form.message_field"
        />

        <Captcha
          v-model="recommendation.captcha"
          class="m-4"
          :data="response.form.captcha"
        />
        <p
          class="mx-4 my-8 font-black"
          v-html="response?.form.privacy_notices"
        />
        <div class="mx-4">
          <div v-if="error.title" class="flex flex-col">
            <Headline class="text-red-500" :title="error.title" />
            <template v-for="message in error.detail" :key="message">
              <span class="text-red-500" v-html="message" />
            </template>
          </div>
          <Headline v-if="msg !== ''" class="text-green-500" :title="msg" />
        </div>
        <div class="p-4">
          <G7Button
            class="w-full p-1"
            :pending="pending"
            :disabled="pending"
            :label="response?.form.action_button.label"
            @click="submit"
          />
        </div>
      </div>
    </ModalBody>
  </ModalBackground>
</template>
<script lang="ts" setup>
import { useForm } from "vee-validate";
import { defaultApiHeaders } from "~/composables/useGo7seasApi";
import type { CruiseRecommendationRequest } from "~/types/request";
import type { CruiseRecommendFormResponse } from "~/types/response";

interface Error {
  title: string;
  detail: string[];
}

const props = defineProps<{
  cruiseid: string;
}>();

const { data: response } = await useGo7seasApi<CruiseRecommendFormResponse>(
  apiUrl("/catalog/cruises/recommendation")
);

const recommendation: CruiseRecommendationRequest = reactive({
  cruise_id: props.cruiseid,
  name: "",
  e_mail: "",
  message: "",
  friends_e_mail: "",
  friends_name: "",
  captcha: {
    captcha_input_code: "",
    captcha_token: "",
  },
});

const pending = ref(false);
const { handleSubmit } = useForm();
const error: Ref<Error> = ref({ title: "", detail: [] });
const msg = ref("");

const submit = handleSubmit(() => {
  pending.value = true;
  msg.value = "";
  error.value = { title: "", detail: [] };

  $fetch
    .raw(apiUrl("/catalog/cruises/recommendation"), {
      method: "POST",
      headers: defaultApiHeaders(),
      body: recommendation,
    })
    .then((response) => {
      if (response.status === 200) {
        msg.value = response._data.message;
      }
    })
    .catch((e) => {
      if (e.response) {
        error.value.title = e.response._data?.title;
        error.value.detail = e.response._data?.fieldErrors.map(
          (fieldError) => fieldError.message
        );
      } else {
        error.value.title = "Es ist ein Fehler aufgetreten.";
        error.value.detail.push("Bitte versuchen Sie es erneut.");
      }
    })
    .finally(() => {
      pending.value = false;
    });
});

const emit = defineEmits(["toggleModal"]);

const recommendModal = ref();

const onClickOutside = (e: MouseEvent) => {
  if (
    recommendModal.value &&
    !recommendModal.value.modalBody.contains(e.target as Node)
  ) {
    emit("toggleModal");
  }
};
useHead({
  bodyAttrs: {
    class: "overflow-y-hidden",
  },
});
</script>
