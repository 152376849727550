<template>
  <div class="w-full text-g7-blue lg:hidden">
    <table>
      <thead>
        <tr>
          <td class="border p-2 uppercase">
            <div class="flex items-center">
              <h2>Termin</h2>
            </div>
          </td>
          <td class="border p-2">
            <div>
              <FieldDropdown v-model="option" :options="options" />
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="border bg-table-orange p-2 uppercase" v-html="cols[0]" />
          <td class="border p-2">
            <div
              class="flex justify-center"
              v-html="item.lafs[0].amount.formatted"
            />
          </td>
        </tr>
        <tr>
          <td class="border bg-table-orange p-2 uppercase" v-html="cols[1]" />
          <td class="border p-2">
            <div
              class="flex justify-center"
              v-html="item.lafs[1].amount.formatted"
            />
          </td>
        </tr>
        <tr>
          <td class="border bg-table-orange p-2 uppercase" v-html="cols[2]" />
          <td class="border p-2">
            <div
              class="flex justify-center"
              v-html="item.lafs[2].amount.formatted"
            />
          </td>
        </tr>
        <tr>
          <td class="border bg-table-orange p-2 uppercase" v-html="cols[3]" />
          <td class="border p-2">
            <div
              class="flex justify-center"
              v-html="item.lafs[3].amount.formatted"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-center lg:hidden">
      <G7Button
        class="my-2 w-fit p-2"
        :label="item.action_button.label"
        :to="item.action_button.context"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SimilarCruise } from "~~/types/cruise";

const props = defineProps<{
  cols: Array<string>;
  cruises: Array<SimilarCruise>;
}>();

const options = computed(() => {
  return props.cruises
    ? props.cruises.map((item) => ({
        label: `${convertDate(
          item.departure_date,
          "DD.MM.YYYY"
        )} - ${convertDate(item.arrival_date, "DD.MM.YYYY")}`,
        value: `${item.departure_date} - ${item.arrival_date}`,
      }))
    : [];
});

const option = ref(options.value[0]);
const item = ref(props.cruises[0]);

watch(option, () => {
  item.value = props.cruises.filter(
    (item) =>
      `${item.departure_date} - ${item.arrival_date}` === option.value.value
  )[0];
});
</script>
