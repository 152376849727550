<template>
  <div>
    <Headline v-if="title" class="m-4 text-center" :title="title" />
    <div>
      <G7Button
        v-if="!openModal"
        class="w-full p-2 text-base font-black md:hidden"
        color="orange"
        label="Deckspläne anzeigen"
        @click="openModal = !openModal"
      />
      <ShipDeckModal
        v-if="openModal"
        :items="items"
        :nav="dropdown"
        @toggle-modal="openModal = !openModal"
      />
    </div>

    <div class="mx-auto my-4 hidden w-fit items-center justify-center md:flex">
      <Headline class="whitespace-nowrap" size="XL" :title="dropdown.label" />
      <FieldDropdown
        v-model="deckType"
        class="ml-4 w-fit"
        :options="dropdown.options"
      />
    </div>

    <div class="hidden md:flex">
      <div
        v-if="filteredDecks.cabin_type_class_sections.length != 0"
        :class="{ 'lg:w-5/6': image }"
        class="flex w-full flex-col"
      >
        <div
          v-for="(deck, index) in filteredDecks.cabin_type_class_sections"
          :key="index"
          class="my-4"
        >
          <div
            class="flex w-full cursor-pointer border-2"
            :class="{
              'bg-white text-g7-blue': open == index,
              'bg-g7-blue text-white': open != index,
            }"
            @click="toggle(index)"
          >
            <div class="flex w-full justify-between">
              <h2
                class="m-2 text-xl font-black uppercase lg:m-5"
                v-html="deck.title"
              />
            </div>
            <div
              class="flex w-12 items-center justify-center border-l-2 lg:w-16"
            >
              <div class="w-6 lg:w-8">
                <IconsUp
                  class="transition"
                  :class="{
                    'rotate-180 fill-g7-blue': open == index,
                    'fill-white': open != index,
                  }"
                />
              </div>
            </div>
          </div>
          <div
            v-if="deck.cabin_types.length != 0"
            class="w-full"
            :class="{ hidden: open != index }"
          >
            <div v-for="(cabin, subindex) in deck.cabin_types" :key="subindex">
              <ShipCabinCard :item="cabin" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filteredDecks.cabin_type_class_sections.length == 0"
        :class="{ 'lg:w-full': !image, 'lg:w-5/6': image }"
      >
        <Headline class="text-center" title="Keine Kabinen vorhanden" />
      </div>
      <div
        v-if="image && !isMobile"
        class="my-4 ml-4 hidden w-1/6 border-2 lg:block"
      >
        <div class="flex h-full items-center justify-center">
          <CloudinaryImg
            :key="image"
            :public-id="image"
            :steps="[400]"
            class="w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import type { DeckDetail } from "~~/types/ships";
import type { SelectBox } from "~~/types/form";

const props = defineProps<{
  title: string;
  dropdown: SelectBox;
  items: Array<DeckDetail>;
}>();

const { isMobile } = useIsMobile();

const deckType = ref(props.dropdown.options[0]);
const openModal: Ref<boolean> = ref(false);
const open = ref(0);

function toggle(index) {
  if (open.value === index) {
    open.value = null;
  } else {
    open.value = index;
  }
}
const filteredDecks = computed(() => {
  return props.items.filter(
    (item) => item.select_box_value === deckType.value.value
  )[0];
});

const image = computed(() => {
  return filteredDecks.value.deck_plan_image.public_id;
});

watch(filteredDecks, () => {
  open.value = 0;
});
</script>
