<template>
  <ModalBackground @click="onClickOutside">
    <div
      ref="deckModal"
      class="relative z-[120] flex size-5/6 flex-col border border-black bg-white drop-shadow-lg"
    >
      <div class="flex items-center justify-between bg-g7-blue">
        <CloudinaryImg
          public-id="go7seas-logo"
          :steps="[200, 400, 600]"
          class="my-4 ml-4 w-40"
        />
        <div
          class="mr-4 flex size-10 rotate-45 cursor-pointer items-center justify-center rounded-full bg-white"
          @click="emit('toggleModal')"
        >
          <IconsPlus class="size-8 fill-g7-blue" />
        </div>
      </div>
      <div class="z-[120] flex w-full flex-col border-b border-black p-2">
        <div class="flex items-center justify-between">
          <div class="size-8">
            <IconsUp
              :class="{ hidden: index == 0 }"
              class="size-8 -rotate-90 cursor-pointer fill-g7-blue"
              @click="prev"
            />
          </div>

          <Headline :title="selectedDeck.label" class="font-black" />
          <div class="size-8">
            <IconsUp
              :class="{ hidden: index == items.length - 1 }"
              class="size-8 rotate-90 cursor-pointer fill-g7-blue"
              @click="next"
            />
          </div>
        </div>
      </div>
      <div class="h-full overflow-y-auto px-10 py-2 sm:px-20">
        <CloudinaryImg
          v-if="selectedItem.deck_plan_image.public_id !== ''"
          :key="selectedItem.deck_plan_image.public_id"
          :public-id="selectedItem.deck_plan_image.public_id"
          :steps="[640]"
          class="w-full"
        />
        <Headline
          v-if="selectedItem.deck_plan_image.public_id == ''"
          title="Kein Bild vorhanden"
        />
      </div>
    </div>
  </ModalBackground>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import type { SelectBox } from "~~/types/form";
import type { DeckDetail } from "~~/types/ships";

const props = defineProps<{
  nav: SelectBox;
  items: Array<DeckDetail>;
}>();

const emit = defineEmits(["toggleModal"]);
const onClickOutside = (e: MouseEvent) => {
  if (deckModal.value && !deckModal.value.contains(e.target as Node)) {
    emit("toggleModal");
  }
};
const index: Ref<number> = ref(0);
const selectedDeck = computed(() => {
  return props.nav.options[index.value];
});

const selectedItem = computed(() => {
  return props.items[index.value];
});

function next(): void {
  if (index.value !== props.items.length - 1) {
    index.value++;
  }
}

function prev(): void {
  if (index.value !== 0) {
    index.value--;
  }
}
const deckModal = ref<HTMLDivElement>(null);

useHead({
  bodyAttrs: {
    class: "overflow-y-hidden",
  },
});
</script>
