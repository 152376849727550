<template>
  <div class="my-8 border border-teal/50 lg:border-2 lg:border-inherit">
    <div class="flex">
      <div class="w-1/2 md:h-full lg:w-1/3 lg:border-r-2">
        <CloudinaryImg
          :public-id="item.image.public_id"
          :steps="[200, 400, 600, 800]"
          class="size-full object-cover md:h-[22rem] xl:h-96"
          :class="{ 'h-[8.5rem]': showText }"
        />
      </div>
      <div class="flex w-1/2 flex-col justify-between text-g7-blue lg:w-2/3">
        <div class="flex h-full flex-col px-2 py-4 md:p-4 lg:p-6">
          <Headline
            v-if="item.title"
            class="mb-4 line-clamp-1 font-black md:mb-2"
            :title="item.title"
          />
          <div v-if="item.text" :id="divId" class="hidden grow md:block">
            <p
              :id="textId"
              :class="classes"
              class="font-bold"
              v-html="item.text"
            />
          </div>
        </div>
        <div class="flex justify-end justify-items-end">
          <G7Button
            v-if="item.action_button && item.text && showButton"
            class="p-1 text-sm sm:text-base"
            color="teal"
            :label="!showText ? item.action_button.label : 'Minimieren'"
            @click="showText = !showText"
          />
        </div>
      </div>
    </div>
    <div
      v-if="item.text"
      class="p-4 text-justify md:hidden"
      :class="{ hidden: !showText }"
    >
      <p class="font-bold text-g7-blue" v-html="item.text" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import type { ShipFacility } from "~/types/ships";

const props = defineProps<{
  id: number;
  item: ShipFacility;
}>();

const classes = ref("");
const divId = ref(`content_${props.id}`);
const textId = ref(`text_${props.id}`);

function renderText() {
  const containerHeight = document.getElementById(divId.value)?.offsetHeight;
  const textElement = document.getElementById(textId.value);
  if (textElement) {
    textElement.innerHTML = props.item.text;

    const result = containerHeight / textElement.offsetHeight;

    if (result > 1) {
      showButton.value = false;
    }
    if (result < 1) {
      classes.value = "line-clamp-5";
    }
  }
}

onMounted(() => {
  if (process.client) {
    renderText();
  }
});

const showText: Ref<boolean> = ref(false);
const showButton: Ref<boolean> = ref(true);

watch(showText, () => {
  if (showText.value) {
    classes.value = "";
  }
  if (!showText.value) {
    classes.value = "line-clamp-5";
  }
});
</script>
