<template>
  <div
    v-if="hasCruises"
    class="flex w-full flex-col justify-center text-g7-blue"
  >
    <CruiseFurtherDatesTableDesk
      class="hidden lg:block"
      :cols="cols"
      :cruises="cruises"
    />
    <CruiseFurtherDatesTableMobile
      class="lg:hidden"
      :cols="cols"
      :cruises="cruises"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SimilarCruise } from "~~/types/cruise";

const props = defineProps<{
  cols: Array<string>;
  cruises: Array<SimilarCruise>;
}>();

const hasCruises = computed(() => {
  return Array.isArray(props.cruises) && props.cruises.length > 0;
});
</script>
