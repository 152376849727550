<template>
  <div class="text-g7-blue">
    <Headline :class="classes" class="font-black" :title="data.title" />
    <div
      v-for="(item, index) in data.review_category_names"
      :key="index"
      class="flex items-center"
    >
      <div
        class="cursor-pointer text-sm underline md:text-base lg:text-2xl"
        @click="scrollToPosition(item.context as string)"
        v-html="`> ${item.label}`"
      />
    </div>
    <div v-for="(category, index) in data.review_categories" :key="index">
      <ShipReviewCategory
        :align="align"
        :category="category"
        @toggle-modal="openModal"
      />
    </div>
    <Mygo7seasShipRatingModal
      v-if="isOpen && shipRating"
      :item="shipRating"
      @toggle-modal="isOpen = !isOpen"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ShipRatingItem } from "~/types/profile";
import type { ShipRatingsResponse } from "~/types/response";

const props = defineProps<{
  data: ShipRatingsResponse;
  align: "left" | "center";
}>();

const alignClass = {
  left: "text-left",
  center: "text-center",
};
const classes = computed(() => {
  return alignClass[props.align];
});

const isOpen = ref(false);
const shipRating = ref(null);

function scrollToPosition(value: string) {
  scrollToIdPosition(value.replace("#", ""));
}

function openModal(value: ShipRatingItem) {
  isOpen.value = !isOpen.value;
  shipRating.value = value;
}
</script>
