<template>
  <div
    v-if="
      promotion.title !== '' ||
      promotion.subtitle !== '' ||
      promotion.description !== '' ||
      promotion.smallprint !== ''
    "
    :class="`my-4 px-5 py-5 border-y-8 text-g7-blue`"
    :style="`border-color: ${promotion.border_color}; background-color: ${promotion.background_color};`"
  >
    <div class="flex items-center justify-between">
      <Headline class="w-3/4 font-black" :title="promotion.title" />
      <G7Button
        class="p-1"
        color="teal"
        :label="
          !show
            ? promotion.show_more_button?.label
            : promotion.show_less_button?.label
        "
        @click="show = !show"
      />
    </div>

    <div v-if="show" class="flex flex-col pt-2 lg:flex-row lg:py-2">
      <div class="w-fit">
        <p v-html="promotion.description" />
        <p class="pt-2 text-xs" v-html="promotion.smallprint" />
      </div>
      <div class="mt-2 flex justify-center lg:mt-0 lg:pl-4">
        <CloudinaryImg
          v-if="promotion.image"
          :public-id="promotion.image.public_id"
          :steps="[200, 400, 600]"
          class="w-60 min-w-60"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { Ref } from "vue";
import type { CruisePromotion } from "~~/types/cruise";

defineProps<{
  promotion: CruisePromotion;
}>();

const show: Ref<boolean> = ref(false);
</script>
