<template>
  <div>
    <HomeHeroSlider class="mt-2 sm:mt-4 lg:mt-auto" />

    <Headline
      :h="1"
      class="m-4"
      size="3XL"
      :title="response.harbour_details.title"
    />

    <hr class="border-2 border-solid border-inherit drop-shadow-lg" />

    <FieldParagraph
      v-if="response.harbour_details.description"
      class="m-4"
      :title="response.harbour_details.description.label"
      :text="response.harbour_details.description.value"
    />

    <FieldParagraph
      v-if="response.harbour_details.sights"
      class="m-4"
      :title="response.harbour_details.sights.label"
      :text="response.harbour_details.sights.value"
    />

    <hr
      v-if="
        response.harbour_details.sights || response.harbour_details.description
      "
      class="border-2 border-solid border-inherit"
    />

    <WeatherInfo
      v-if="response.harbour_details.weather_information"
      class="hidden lg:block"
      :weather-data="response.harbour_details.weather_information"
    />

    <FieldParagraph
      v-if="response.harbour_details.speciality"
      class="m-4"
      :title="response.harbour_details.speciality.label"
      :text="response.harbour_details.speciality.value"
    />

    <div class="m-4 flex flex-col text-g7-blue lg:flex-row">
      <div
        v-if="response?.harbour_details.language"
        class="mb-2 flex items-end lg:mb-0"
      >
        <h2
          class="font-black lg:text-3xl"
          v-html="response.harbour_details.language.label + ':'"
        />
        <span
          class="ml-2 lg:text-2xl"
          v-html="response.harbour_details.language.value"
        />
      </div>

      <div
        v-if="response?.harbour_details.currency"
        class="flex items-end lg:ml-6"
      >
        <h2
          class="font-black lg:text-3xl"
          v-html="response.harbour_details.currency.label + ':'"
        />
        <span
          class="ml-2 lg:text-2xl"
          v-html="response.harbour_details.currency.value"
        />
      </div>
    </div>
    <hr class="border-2 border-solid border-inherit" />

    <CruiseList
      class="mx-4"
      :title="offers.title"
      :button="offers.action_button"
      :offers="offers.cruises"
    />
  </div>
</template>

<script setup lang="ts">
import type { Links } from "~~/types/layout";
import type { CruiseListResponse, HarbourResponse } from "~~/types/response";

const props = defineProps<{
  links: Links;
}>();

const { data: response } = await useGo7seasApi<HarbourResponse>(
  props.links.self.href
);

useDiaShow().set(response.value?.dia_show.dias ?? []);

const { data: offers } = await useGo7seasApi<CruiseListResponse>(
  props.links.cruises.href
);

updateDefaultLayout(response.value);
</script>
