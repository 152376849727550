<template>
  <div :id="category.anchor_id" class="text-g7-blue">
    <Headline
      class="my-8 font-black"
      :class="classes"
      :title="category.review_category_name"
    />
    <div class="flex flex-col lg:grid lg:grid-cols-8">
      <div
        v-if="category.total_expert_rating?.value"
        class="flex items-center lg:col-start-2"
      >
        <Headline :title="category.total_expert_rating.label" />
        <IconsRating
          class="ml-1 sm:ml-2 lg:ml-4"
          :rating="category.total_expert_rating.value"
        />
      </div>
      <div
        v-if="category.total_customer_rating?.value"
        class="flex items-center lg:col-start-6"
      >
        <Headline :title="category.total_customer_rating.label" />
        <IconsRating
          class="ml-1 sm:ml-2 lg:ml-4"
          :rating="category.total_customer_rating.value"
          color="orange"
        />
      </div>
    </div>
    <Bar class="mt-4 justify-center" :title="category.title" />
    <div class="border lg:border-2">
      <Headline class="m-2 font-black" :title="category.subtitle" />
    </div>
    <ShipCustomerRating
      v-for="(review, index) in category.customer_ratings"
      :key="index"
      :class="{
        hidden: index >= limit,
      }"
      :item="review"
      @toggle-modal="emit('toggleModal', $event)"
    />
    <div class="my-4 flex justify-center">
      <ShipShowComments
        v-if="limit == 2 && category.customer_ratings.length > 2"
        :item="category.show_more_button"
        @click="limit = category.customer_ratings?.length"
      />
      <ShipShowComments
        v-if="limit == category.customer_ratings?.length"
        :item="category.show_less_button"
        @click="limit = 2"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ShipRatingDetails } from "~/types/profile";
import type { ReviewCategory } from "~/types/ships";

const props = defineProps<{
  align: "left" | "center";
  category: ReviewCategory;
}>();

const alignClass = {
  left: "text-left",
  center: "text-center",
};
const classes = computed(() => {
  return alignClass[props.align];
});

const limit = ref(2);

const emit = defineEmits<{
  (e: "toggleModal", value: ShipRatingDetails): void;
}>();
</script>
