<template>
  <div class="flex flex-col border-x border-b lg:border-x-2 lg:border-b-2">
    <div class="flex flex-col lg:flex-row lg:items-center">
      <div class="flex items-center">
        <Headline class="m-2 font-black" :title="item.publish_name" />
        <div
          class="flex size-6 items-center justify-center rounded-sm bg-g7-blue p-1"
        >
          <IconsUser class="size-4 fill-white" />
        </div>
      </div>
      <div class="flex items-center">
        <span
          class="ml-2 cursor-pointer underline"
          @click="emit('toggleModal', item.details)"
          v-html="item.action_button?.label"
        />
        <IconsRating
          class="ml-4"
          :rating="item.total_rating.value"
          color="orange"
        />
      </div>
    </div>
    <p class="mx-2 mb-4" v-html="item.overall_opinion" />
  </div>
</template>

<script lang="ts" setup>
import type { ShipRatingDetails } from "~/types/profile";
import type { CustomerShipRating } from "~/types/ships";

defineProps<{
  item: CustomerShipRating;
}>();

const emit = defineEmits<{
  (e: "toggleModal", value: ShipRatingDetails): void;
}>();
</script>
