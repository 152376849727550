<script lang="ts" setup>
import type { Ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import type { LabelValue } from "@/types/form";
import type { Links } from "@/types/layout";

const props = defineProps<{
  tabs: Array<LabelValue>;
  links: Links;
  cruiseid: string;
  bookmarked: boolean;
}>();

const active = useState("cruiseTab", () => "");
const apiLink: Ref<string> = ref("");
const page = ref();

interface Map {
  [key: string]: unknown;
}

const mapping: Map = {
  itinerary: markRaw(
    defineAsyncComponent(() => import("@/views/reisetabs/itinerary.vue"))
  ),
  route: markRaw(
    defineAsyncComponent(() => import("@/views/reisetabs/route.vue"))
  ),
  included_services: markRaw(
    defineAsyncComponent(
      () => import("@/views/reisetabs/included_services.vue")
    )
  ),
  cruise_experts: markRaw(
    defineAsyncComponent(() => import("@/views/reisetabs/cruise_experts.vue"))
  ),
  life_on_board: markRaw(
    defineAsyncComponent(() => import("@/views/reisetabs/life_on_board.vue"))
  ),
  ship_details: markRaw(
    defineAsyncComponent(() => import("@/views/reisetabs/ship_details.vue"))
  ),
  ship_ratings: markRaw(
    defineAsyncComponent(() => import("@/views/reisetabs/ship_ratings.vue"))
  ),
};

function navigate(label: string): void {
  label = label.startsWith("TAB_") ? label.substring(4) : label;
  active.value = label;
}

function isActive(label: string): boolean {
  label = label.startsWith("TAB_") ? label.substring(4) : label;
  if (active.value) {
    return label === toUpperSnake(active.value);
  }
  return false;
}

onMounted(() => {
  navigate("ITINERARY");
});

watch(active, () => {
  const linkLabel = toUpperSnake(
    active.value.startsWith("TAB_") ? active.value : `tab_${active.value}`
  );
  if (props.links[linkLabel]) {
    apiLink.value = props.links[linkLabel].href;
    page.value = mapping[active.value.toLowerCase()];
  }
});

const emit = defineEmits(["toggleModal"]);

onBeforeRouteLeave(() => {
  useState("cruiseTab").value = "";
});

onBeforeRouteUpdate(() => {
  useState("cruiseTab").value = "";
});
</script>

<template>
  <section>
    <div>
      <Headline
        :h="1"
        class="m-4 hidden text-center lg:block"
        size="3XL"
        :title="'Weitere Reisedetails'"
      />

      <HorizontalScroller
        color="white"
        class="border-2 border-solid border-inherit"
        classes="max-w-screen"
      >
        <template #scrollableContent>
          <G7Button
            v-for="(tab, index) in tabs"
            :key="index"
            class="!sm:px-4 !px-8 !py-4 text-sm md:text-base"
            :class="{
              '!bg-white !text-g7-blue underline': isActive(tab.value as string),
              'ml-1': index > 0,
            }"
            :label="tab.label"
            @click="navigate(tab.value as string)"
          />
        </template>
        <template #additionalContent>
          <CruiseTabIcons
            :bookmarked="bookmarked"
            :cruiseid="cruiseid"
            class="z-10 hidden items-center xl:flex"
            @toggle-modal="emit('toggleModal')"
          />
        </template>
      </HorizontalScroller>
    </div>
    <component :is="page" :url="apiLink" class="m-5" />
  </section>
</template>
