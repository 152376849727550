<template>
  <ModalBackground @click="onClickOutside">
    <ModalBody ref="itineraryModal" @toggle="emit('toggleModal')">
      <div>
        <Bar class="my-4 uppercase" title="Route" />
        <div class="max-h-[50vh] w-full overflow-y-auto">
          <CruiseItineraryTable :itinerary="itinerary" />
        </div>
      </div>
    </ModalBody>
  </ModalBackground>
</template>
<script lang="ts" setup>
import type { CruiseItineray } from "~/types/cruise";

defineProps<{
  itinerary: CruiseItineray[];
}>();

const emit = defineEmits(["toggleModal"]);

const itineraryModal = ref();

const onClickOutside = (e: MouseEvent) => {
  if (
    itineraryModal.value &&
    !itineraryModal.value.modalBody.contains(e.target as Node)
  ) {
    emit("toggleModal");
  }
};
useHead({
  bodyAttrs: {
    class: "overflow-y-hidden",
  },
});
</script>
