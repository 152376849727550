<template>
  <div class="text-g7-blue">
    <Headline class="m-4 text-center" :title="calendar.title" />
    <div class="flex w-full justify-center text-xs sm:text-base">
      <table class="w-full">
        <thead>
          <tr class="font-bold">
            <td class="border p-2 lg:border-2 lg:p-4">
              {{ calendar.column_title_regions }}
            </td>

            <td class="border p-2 lg:border-2 lg:p-2.5">
              <div class="flex justify-center">
                <IconsUp
                  :class="{ 'fill-slate-500': !isNotStart }"
                  class="size-6 -rotate-90 cursor-pointer fill-g7-blue sm:size-8 lg:size-14"
                  @click="shiftL"
                />
              </div>
            </td>

            <td
              v-for="(col, index) in titles"
              :key="index"
              class="border p-2 text-center lg:border-2 lg:p-4"
              v-html="col"
            />

            <td class="border p-2 lg:border-2 lg:p-2.5">
              <div class="flex justify-center">
                <IconsUp
                  :class="{ 'fill-slate-500': !isNotEnd }"
                  class="size-6 rotate-90 cursor-pointer fill-g7-blue sm:size-8 lg:size-14"
                  @click="shiftR"
                />
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(row, rowIndex) in rows"
            :key="rowIndex"
            :class="{ hidden: rowIndex >= limit }"
            class="odd:bg-table-orange even:bg-white"
          >
            <td
              class="max-w-20 overflow-hidden text-ellipsis border p-2 sm:max-w-none lg:border-2 lg:p-5"
            >
              {{ row.cruise_region_name }}
            </td>
            <td class="border p-2 lg:border-2 lg:p-5" />

            <td
              v-for="(col, colIndex) in row.availability_slugs"
              :key="colIndex"
              class="border p-2 lg:border-2 lg:p-5"
            >
              <div class="flex justify-center">
                <div
                  v-if="col"
                  class="flex size-6 cursor-pointer items-center justify-center rounded-full border border-teal bg-white lg:size-10 lg:border-2"
                  @click="navigate(col)"
                >
                  <IconsCheck class="size-4 fill-teal lg:size-8" />
                </div>
                <div v-if="col == null" class="size-6 lg:size-10" />
              </div>
            </td>

            <td class="border p-2 lg:border-2 lg:p-5" />
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="limit < rows.length" class="justify-center lg:m-4 lg:flex">
      <G7Button
        class="mt-2 w-full p-2 text-base font-black lg:w-auto"
        color="orange"
        :label="calendar.load_more_label"
        @click="limit = rows.length"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import type { CruiseCalendar } from "~~/types/cruise";

const props = defineProps<{
  calendar: CruiseCalendar;
}>();

const offset = computed(() => {
  if (process.server) {
    return 11;
  }
  return window.innerWidth <= 640
    ? 2
    : window.innerWidth <= 1024
    ? 5
    : window.innerWidth <= 1280
    ? 7
    : 11;
});
const start: Ref<number> = ref(0);
const limit: Ref<number> = ref(3);

const titles = computed(() => {
  return props.calendar.column_titles_dates.filter(
    (_, index) => index >= start.value && index <= start.value + offset.value
  );
});

const rows = computed(() => {
  return props.calendar.calendar_rows
    ? props.calendar.calendar_rows.map((item) => ({
        cruise_region_name: item.cruise_region_name,
        availability_slugs: item.availability_slugs.filter(
          (_, index) =>
            index >= start.value && index <= start.value + offset.value
        ),
      }))
    : [];
});
function navigate(value) {
  useRouter().push(value);
}
const isNotStart = computed(() => start.value !== 0);

function shiftL() {
  if (isNotStart.value) {
    start.value--;
  }
}
const isNotEnd = computed(
  () =>
    start.value + offset.value < props.calendar.column_titles_dates.length - 1
);
function shiftR() {
  if (isNotEnd.value) {
    start.value++;
  }
}
</script>
