<template>
  <div class="flex items-center">
    <div
      class="mx-1 hidden size-8 cursor-pointer items-center justify-center rounded-full bg-teal lg:mx-2 lg:size-10"
    >
      <IconsFileArrowDown class="size-5 fill-white lg:size-6" />
    </div>
    <div
      :class="{ '!bg-red-500': isActive }"
      class="mx-1 flex size-8 cursor-pointer items-center justify-center rounded-full bg-teal lg:mx-2 lg:size-10"
      @click="like"
    >
      <LoadingSpinner v-if="pending" color="white" size="xs" />
      <IconsHeart v-if="!pending" class="size-5 fill-white lg:size-6" />
    </div>
    <div
      class="mx-1 flex size-8 cursor-pointer items-center justify-center rounded-full bg-teal lg:mx-2 lg:size-10"
      @click="emit('toggleModal')"
    >
      <IconsShare class="size-5 fill-white lg:size-6" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useToast } from "vue-toastification";
import { defaultApiHeaders } from "~/composables/useGo7seasApi";
import type { BookmarksResponse } from "~/types/response";

const emit = defineEmits(["toggleModal"]);
const props = defineProps<{
  bookmarked: boolean;
  cruiseid: string;
}>();
const toast = useToast();
const isActive = ref(props.bookmarked);
const pending = ref(false);

function like() {
  if (!useAuth().valid.value) {
    return alert(
      "Melden Sie sich im mygo7seas Bereich an, um Ihre Favoriten zu speichern."
    );
  }
  if (isActive.value === false && !pending.value) {
    pending.value = true;
    $fetch
      .raw(apiUrl(`/catalog/mygo7seas/bookmarks`), {
        method: "PUT",
        headers: defaultApiHeaders(),
        body: {
          cruise_id: props.cruiseid,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Reise gespeichert");
          isActive.value = true;
        }
      })
      .finally(() => (pending.value = false));
  } else if (isActive.value === true && !pending.value) {
    pending.value = true;
    $fetch
      .raw<BookmarksResponse>(apiUrl("/catalog/mygo7seas/bookmarks"), {
        method: "POST",
        headers: defaultApiHeaders(),
        body: {
          cruises: [props.cruiseid],
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Reise entfernt");
          isActive.value = false;
        }
      })
      .finally(() => (pending.value = false));
  }
}
</script>
