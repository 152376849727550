<template>
  <div>
    <Headline v-if="title" class="text-center" :title="title" />
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="{
        hidden: index >= limitVal,
      }"
    >
      <ListCard v-if="index < limitVal" :id="index" :item="item" />
    </div>
    <div v-if="limitVal < items.length && button" class="flex justify-center">
      <G7Button
        color="orange"
        :label="button"
        @click="limitVal = items.length"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  limit?: number;
  title?: string;
  items: Array<unknown>;
  button?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  limit: 3,
  title: "",
  button: null,
  items: () => [] as unknown[],
});

const limitVal = ref(props.limit);
</script>
