<template>
  <div>
    <Headline v-if="title" class="m-4 text-center" :title="title" />

    <div
      v-if="dropdown"
      class="mx-auto my-4 flex w-fit items-center justify-center"
    >
      <Headline class="whitespace-nowrap" size="XL" :title="dropdown.label" />
      <FieldDropdown
        v-model="cabinType"
        class="ml-4 w-fit"
        :options="dropdown.options"
      />
    </div>

    <div
      v-for="(item, index) in filteredItems"
      :key="index"
      :class="{
        hidden: index >= limitVal,
      }"
    >
      <ShipCabinCard :item="item" />
    </div>
    <div
      v-if="limitVal < filteredItems.length && filteredButton"
      class="flex justify-center"
    >
      <G7Button
        color="orange"
        :label="filteredButton.label"
        @click="limitVal = filteredItems.length"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { LabelValue, SelectBox } from "~~/types/form";
import type { CabinType } from "~~/types/ships";

interface Props {
  limit?: number;
  title: string;
  dropdown?: SelectBox | null;
  items: Array<CabinType>;
  buttons?: Array<LabelValue> | null;
}

const props = withDefaults(defineProps<Props>(), {
  limit: 3,
  title: "",
  dropdown: null,
  items: () => [] as CabinType[],
  buttons: null,
});

const cabinType = ref(
  props.dropdown !== null ? props.dropdown.options[0] : null
);

const filteredItems = computed(() => {
  return cabinType.value !== null
    ? props.items.filter((item) => item.title.includes(cabinType.value.label))
    : props.items;
});

const filteredButton = computed(() => {
  return cabinType.value !== null && props.buttons != null
    ? props.buttons.filter((btn) =>
        btn.value.includes(cabinType.value.value)
      )[0]
    : null;
});

const limitVal = ref(props.limit);
</script>
