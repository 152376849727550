<template>
  <div>
    <Headline class="m-4 text-center" :title="ship.title" />
    <div class="flex w-full justify-center border">
      <div
        class="grid w-full overflow-auto uppercase text-g7-blue lg:grid-flow-col lg:grid-rows-8"
      >
        <div
          v-for="(item, index) in ship.details"
          :key="index"
          :class="{ 'hidden lg:grid': index >= limit }"
          class="grid grid-cols-2 items-center border text-sm odd:bg-white even:bg-table-orange sm:text-base"
        >
          <div class="p-4 font-bold lg:p-5" v-html="item.label" />
          <div class="p-4 lg:p-5" v-html="item.value" />
        </div>
        <template v-if="ship.details.length % 8 != 0">
          <div
            v-for="(_, i) in 8 - (ship.details.length % 8)"
            :key="i"
            class="hidden grid-cols-2 border even:bg-table-orange lg:inline-grid"
          />
        </template>
      </div>
    </div>
    <G7Button
      v-if="limit < ship.details.length"
      class="mt-2 w-full p-2 text-base font-black lg:hidden"
      color="orange"
      label="Mehr Details Anzeigen"
      @click="limit = ship.details.length"
    />
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import type { ShipDetails } from "~~/types/ships";

defineProps<{
  ship: ShipDetails;
}>();

const limit: Ref<number> = ref(5);
</script>
