<template>
  <div>
    <div class="flex flex-wrap justify-center md:justify-between">
      <div>
        <NuxtLink v-if="prevCruise" :to="prevCruise.context">
          <div class="flex items-center">
            <IconsUp class="size-5 -rotate-90 fill-g7-blue sm:size-8" />
            <Headline
              size="XL"
              class="mr-4 underline"
              :title="prevCruise.label"
            />
          </div>
        </NuxtLink>
      </div>
      <div>
        <NuxtLink v-if="nextCruise" :to="nextCruise.context">
          <div class="flex items-center">
            <Headline
              size="XL"
              class="ml-4 underline"
              :title="nextCruise.label"
            />
            <IconsUp class="size-5 rotate-90 fill-g7-blue sm:size-8" />
          </div>
        </NuxtLink>
      </div>
    </div>
    <div class="my-4 flex flex-col items-center">
      <Headline v-if="cruises" class="my-2" :title="cruises.title" />
      <CruiseFurtherDatesTable
        v-if="cruises"
        :cols="cruises.column_titles"
        :cruises="cruises.similar_cruises"
      />
    </div>
    <CruiseTabIcons
      :bookmarked="bookmarked"
      :cruiseid="cruiseid"
      class="my-4 justify-center xl:hidden"
      @toggle-modal="emit('toggleModal')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { LabelContext } from "~~/types/form";
import type { SimilarCruiseResponse } from "~~/types/response";

defineProps<{
  cruiseid: string;
  bookmarked: boolean;
  prevCruise: LabelContext;
  nextCruise: LabelContext;
  cruises: SimilarCruiseResponse;
}>();

const emit = defineEmits(["toggleModal"]);
</script>
